import React from 'react';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import './App.css'; // Make sure to import the CSS file

function App() {
  return (
    <div className="App">
            <header className="App-header">
            <h1 className="App-title">WaifuPepe</h1>
      </header>
      <section className="token-info">
        <h2><a href="https://arbiscan.io/token/0x18Bd949d597DC2a6Cf49226F82ceAdc9e3Ed5662">$WPEPE (1,000,000,000)</a></h2>
        <p>WaifuPepe represents a milestone in the world of cryptocurrency, being the first token created by AI on the Arbitrum network using the innovative AI IS DEV platform. Built with a blend of AI creativity and user-guided customization, WaifuPepe symbolizes the cutting-edge intersection of blockchain technology and artificial intelligence.</p>
        <ul>
          <li>First AI-created token on Arbitrum deployed using the AI IS DEV token deployer platform, a pioneering achievement in the blockchain space.</li>
          <li>AI-Driven Development: Leveraged the advanced capabilities of GPT-4 and DALL·E for token parameter generation and visual representation.</li>
          <li>The first AI meme deployed on Arbitrum, an L2 built for cheaper tx fees and quicker confirmations compared to mainnet</li>
          <li>Liquidity Locked + Contract Revoked</li>

        </ul>
      </section>
      <div className="image-container">
        <img src="/waifupepe.png" alt="WaifuPepe" className="waifupepe-image" />
      </div>
      <section className="links">
        <a href="https://t.me/WaifuPepe" className="link telegram">
          <FontAwesomeIcon icon={faTelegramPlane} /> Telegram
        </a>
        <a href="https://dexscreener.com/arbitrum/0x973eebd4438bdb98a25c94af2677e58751179f0a" className="link chart">
          <FontAwesomeIcon icon={faChartLine} /> Chart
        </a>
      </section>
      <footer className="App-footer">
        <a href="https://aiis.dev" className="AI IS DEV APP LINK">
          AI IS DEV 🤖
        </a>
      </footer>
      <section className="contract-address">
        <input 
          type="text" 
          value="0x18Bd949d597DC2a6Cf49226F82ceAdc9e3Ed5662" 
          readOnly
          className="contract-input"
        />
      </section>
    </div>
  );
}

export default App;
